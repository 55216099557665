import { hasFeature } from 'libs/features'

export const wasRoutedFromApp = (state) => {
  const inApp = state?.routing?.locationBeforeTransitions?.query['in-app']

  const params = new URLSearchParams(window.location.search)
  return params.get('in-app') === 'true' || inApp
}

export const isTablet = (state) => {
  const deviceType = state?.routing?.locationBeforeTransitions?.query['device-type']
  return deviceType ? deviceType.toLowerCase() === 'tablet' : false
}

export const isHubRoutingEnabled = (state) => {
  console.debug(
    'debug: isHubRoutingEnabled',
    !hasFeature(state, 'linkedAccounts', false),
    !wasRoutedFromApp(state)
  )
  return !hasFeature(state, 'linkedAccounts', false) && !wasRoutedFromApp(state)
}
