import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'

import UserAlert from 'components/notifications/UserAlert'
import { pageViewed, userLoggedIn } from 'libs/analytics'
import classes from 'containers/app/app.module.scss'
import { getCookie, saveSession } from 'libs/cookies'
import { loggedIn } from 'redux/modules/session'
import { callJSONApi } from 'libs/api'
import { wasRoutedFromApp, isHubRoutingEnabled } from 'redux/modules/routes'
import Alert from 'components/notifications/Alert'
import { getENV } from 'libs/utils'
import PaddedContent from 'ui/layout/PaddedContent'
import FullscreenLoadingPage from 'components/loaders/FullscreenLoadingPage'
// Fonts
/* eslint-disable */
import yocoFont from 'assets/styles/fonts/yoco/yoco.module.scss'
import yocoRegisterFont from 'assets/styles/fonts/yocoRegister/yoco-register.module.scss'
import collectionIcons from 'assets/styles/fonts/collectionIcons/collection-icons.module.scss'

// New fonts
import sharpGrotesk from 'assets/styles/fonts/sharpGrotesk/sharpGrotesk.module.scss'

// Theme
import theme from 'assets/styles/theme.module.scss'

// Polyfills
import Storage from './polyfills/localStoragePolyfill'
import RequestAnimationFrame from './polyfills/requestAnimationFramePolyfill'
import {
  selectHasCompletedOnboarding,
  selectIsSettlementActivated,
} from 'redux/modules/session/selectors'
/* eslint-enable */

// Mapping of portal routes to hub routes.
// When no mapping is found, we return "false," indicating that we should not redirect.
// This logic is necessary, as we want to redirect to the hub for certain routes, but not for
// others such as Shopify routes (those aren't implemented in the hub yet)
const portalToHubRouteMapping = (next) => {
  const { pathname } = new URL(
    next,
    'http://localhost:1' /* This URL is not used, and is only used as a placeholder to construct a URL object */
  )

  switch (pathname) {
    // Front page
    case '/dashboard':
    case '/onboarding':
      return '/hub'
    // Invoices
    case '/invoice/invoices':
      return '/sales/invoices/home'
    // Payment Links
    case '/online/payment-links':
      return '/sales/payments/payment-links/list'
    // Payment Gateway
    case '/online/plugin-keys':
      return '/sales/payments/payment-gateway'
    // Referrals
    case '/lets-grow':
      return '/app/business-settings/refer-a-friend'
    // Sales History
    case '/sales/history':
      return '/sales/payments/sales-history'
    // Reports
    case '/sales/reports':
      return '/reports/dashboard'
    // Invoices
    case '/sales/invoices':
    case '/invoice/invoices-settings':
      return '/sales/invoices/home'
    // Payouts
    case '/sales/settlements':
      return '/money/payouts/history'
    // Payment Page
    case '/online/settings':
      return '/sales/payments/payment-page'
    // Products management
    case '/store/products':
    case '/store/brands-and-categories':
      return '/manage/products/home'
    // Staff management
    case '/store/staff':
      return '/manage/staff/home'
    // Customer management
    case '/store/customers':
      return '/manage/invoices/customers/list'
    // Gift vouchers
    case '/online/vouchers':
      return '/sales/payments/voucher'
    // Personal settings
    case '/profile/edit':
      return '/app/personal-settings/view'
    // Business settings
    case '/business/details':
      return '/app/business-settings'
    // Card machine settings:
    case '/business/card-readers':
    case '/card-machines/buy':
      return '/app/business-settings/card-machines/list'
    // Communication Preferences
    case '/business/email-notifications':
      return '/app/business-settings/communication-preferences/home'
    // Bank details
    case '/business/bank-account':
      return '/app/business-settings/bank-account'
    // Receipt settings
    case '/business/bill-receipt':
      return '/app/business-settings/receipt'
    case '/newportal':
      return '/hub'
    default:
      return false
  }
}

export const redirectHubOrNext = (next, hasLoggedIntoHub, routeToHub) => {
  const nextRoute = portalToHubRouteMapping(next)
  console.debug('debug: redirectHubOrNext', next, hasLoggedIntoHub, nextRoute, routeToHub)
  if (hasLoggedIntoHub && nextRoute && routeToHub) {
    const location = new URL(`${getENV('HUB_BASE_URL')}${nextRoute}`)
    window.location.replace(location)
    return location
  }
  return next
}

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorMessage: null,
      isLoadingSession: true,
    }
  }

  UNSAFE_componentWillMount() {
    // IF THE BROWSER IS INTERNET EXPLORER 10
    if (navigator.appVersion.indexOf('MSIE 10') !== -1) {
      this.setState({
        ie10: true,
      })
    }

    this.checkNextPath()
  }

  componentDidMount() {
    this.loadOrRefreshSession()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.path !== this.props.path) {
      pageViewed(nextProps.path)
    }
    // Necessary for handling refreshing of any page in portal as it triggers the initial route
    const isGoingToLogin = nextProps.path === '/' || nextProps.path.includes('login')
    const shouldStayOnCurrentPage =
      nextProps.loggedIn && isGoingToLogin && !this.props.path.includes('login')
    if (shouldStayOnCurrentPage && !nextProps.routeToHub) {
      this.props.dispatch(push(this.props.path + this.props.location.search))
    }
  }

  componentDidUpdate() {
    const isLoggedIn = this.props.loggedIn
    if (!isLoggedIn && !this.props.isInApp) {
      const postLoginRedirectUrl = this.getPostLoginRedirectUrl()
      this.props.dispatch(
        push(redirectHubOrNext(postLoginRedirectUrl, !isLoggedIn, this.props.routeToHub))
      )
    }
  }

  getIE10() {
    if (this.state.ie10) {
      return (
        <UserAlert
          userAlert={{
            htmlMessage: `You are using an old browser. Certain things will not work correctly. Yoco recommends updating your browser, following this <a href="http://outdatedbrowser.com/" target="_blank">link</a> to do so easily.</br></br>This browser may become <b>unsupported</b> in the near future.</b>`,
            alertType: 'warning',
          }}
          dismissUserAlert={() => this.setState({ ie10: false })}
          style={{ top: '67px' }}
        />
      )
    }
    return null
  }

  getPostLoginRedirectUrl() {
    const path = this.props.path || '/'

    let baseUrl
    if (path === '/' || path === '/account/login') {
      baseUrl = this.props.hasCompletedOnboarding ? '/dashboard' : '/onboarding'
    } else {
      baseUrl = path
    }

    return baseUrl + this.props.location.search
  }

  getLoginRedirectURL() {
    // If we do not have a session, this is the URL to push the user to to login and get back to their intended destination
    if (this.props.path.startsWith('/account/login')) {
      return this.props.path
    }

    if (this.props.isInApp) {
      const encodedPathname = encodeURIComponent(this.props.location.pathname)
      const searchParams = this.props.location.search
      return `/account/login?next=${encodedPathname}${searchParams}`
    }

    return '/newportal'
  }

  getWarning() {
    return `<!--[if lte IE 9]>
      <div class=${classes.oldWarning}>
        <div class="title">
          Browser not supported
        </div>
        <div class="subtitle">
          Unfortunately the Yoco Business Portal does not support this browser version.<br/><br/>
          Please click the button below to update your browser.
        </div>
        <div class="update-button">
          <a href="http://outdatedbrowser.com/">Update browser</a>
        </div>
        <div class="comments">
          Using an outdated browser opens you up to security risks, slows down your computer and causes websites to display incorrectly.</br>
          Keeping your browser up to date is highly recommended.
        </div>
        <div class="support">
          Should you require some assistance, give us a call</br>087 231 0273
        </div>
      </div>
    <![endif]-->`
  }

  loadOrRefreshSession() {
    // Once we have loaded our session - which page should we direct to
    const postLoginRedirectUrl = this.getPostLoginRedirectUrl()

    let session = getCookie('signUpSession')
    if (!session || session.length < 1) {
      session = window?.yocoStorage?.getItem('session')
    }

    if (session) {
      // We actually refresh this user session each time the page is
      // refreshed. We will continue to show the loader until we receive
      // the result from `/user/session` and then redirect the user to
      // either the dashboard, or the onboarding page
      console.log('Using session inflated from local storage')

      const jsonSession = JSON.parse(session)
      saveSession(jsonSession)
      userLoggedIn(jsonSession.user)
      this.props.dispatch(loggedIn(jsonSession))

      callJSONApi(
        '/user/session',
        'GET',
        {},
        (response) => {
          saveSession(response.data)
          this.props.dispatch(loggedIn(response.data))
          this.props.dispatch(
            push(
              redirectHubOrNext(
                postLoginRedirectUrl,
                response.data.hasLoggedInToHub,
                this.props.routeToHub
              )
            )
          )
        },
        (prettyError) => {
          console.log('Unable to fetch user session', prettyError)
          this.setState({
            errorMessage: `Unable to load user information: ${prettyError}`,
          })
        }
      )
    } else if (getCookie('sessionToken')) {
      console.log('Authenticating using `sessionToken` from app')
      // eslint-disable-next-line no-undef
      yocoStorage.setItem('token', getCookie('sessionToken'))

      callJSONApi(
        '/user/session',
        'GET',
        {},
        (response) => {
          this.setState({ isLoadingSession: false })
          saveSession(response.data)
          userLoggedIn(response.data.user)
          this.props.dispatch(loggedIn(response.data))
          this.props.dispatch(
            push(
              redirectHubOrNext(
                postLoginRedirectUrl,
                response.data.hasLoggedInToHub,
                this.props.routeToHub
              )
            )
          )
        },
        (prettyError) => {
          console.error('Unable to fetch user session, redirecting to login', prettyError)
          this.props.dispatch(push(this.getLoginRedirectURL()))
        }
      )
      // eslint-disable-next-line react/prop-types
    } else if (this.props.isInApp) {
      callJSONApi(
        '/user/session',
        'GET',
        {},
        (response) => {
          this.setState({ isLoadingSession: false })
          saveSession(response.data)
          this.props.dispatch(loggedIn(response.data))

          this.props.dispatch(
            push(
              redirectHubOrNext(
                postLoginRedirectUrl,
                response.data.hasLoggedInToHub,
                this.props.routeToHub
              )
            )
          )
        },
        (prettyError) => {
          console.error('Unable to fetch user session, redirecting to login', prettyError)
          this.props.dispatch(push(this.getLoginRedirectURL()))
        }
      )
    } else {
      // The "yat" cookie is the Yoco Auth Token (JWT) which can be used to auto-login the user in and initiate a session.
      // As an example, the yat is currently set in hello-yoco at the end of the signup funnel.
      const yat = getCookie('yat') || this.props.location.query.yat

      if (yat) {
        callJSONApi(
          '/user/auth',
          'POST',
          {
            service: 'portal',
            token: yat,
          },
          (response) => {
            this.setState({ isLoadingSession: false })
            saveSession(response.data)
            userLoggedIn(response.data.user)
            this.props.dispatch(loggedIn(response.data))

            this.props.dispatch(
              push(
                redirectHubOrNext(
                  postLoginRedirectUrl,
                  response.data.hasLoggedInToHub,
                  this.props.routeToHub
                )
              )
            )
          },
          (prettyError) => {
            console.log('Unable to generate long lived session from yat', prettyError)
            this.props.dispatch(push(this.getLoginRedirectURL()))
          }
        )
      } else if (!this.props.path.startsWith('/account')) {
        // You can access your account pages when you are not logged in
        console.log(`Atttempting to route to ${this.props.path} without a logged in user`)
        this.props.dispatch(push(this.getLoginRedirectURL()))
      }
    }
  }

  checkNextPath() {
    if (this.props.loggedIn) {
      const path = this.props.hasCompletedOnboarding ? '/dashboard' : '/onboarding'
      this.props.dispatch(push(path + this.props.location.search))
    } else if (!this.props.path.startsWith('/account')) {
      this.props.dispatch(push(`/account/login${this.props.location.search}`))
    }
  }

  render() {
    return (
      <div>
        <span dangerouslySetInnerHTML={{ __html: this.getWarning() }} />
        {this.state.errorMessage ? (
          <PaddedContent isTextCentered>
            <Alert message={this.state.errorMessage} messageType='danger' />
          </PaddedContent>
        ) : null}
        {this.state.isLoadingSession && this.props.isInApp ? (
          <FullscreenLoadingPage message='Loading your account information' />
        ) : null}
        {this.props.children}
        {this.getIE10()}
      </div>
    )
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.any,
  path: PropTypes.string,
  loggedIn: PropTypes.bool,
  hasCompletedOnboarding: PropTypes.bool,
  location: PropTypes.object,
  routeToHub: PropTypes.bool,
  isInApp: PropTypes.bool,
}

export default connect((state) => ({
  path: state.routing.locationBeforeTransitions.pathname,
  loggedIn: state.session.get('loggedIn'),
  hasCompletedOnboarding: selectHasCompletedOnboarding(state),
  isInApp: wasRoutedFromApp(state),
  routeToHub: isHubRoutingEnabled(state),
}))(App)
