/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/sort-comp */
/* eslint-disable prefer-destructuring */
import React, { Component } from 'react'
import { Route, Redirect } from 'react-router'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Immutable, { Map } from 'immutable'
import s from 'string'
import ProgressBar from 'react-progress-bar-plus'
import PropTypes from 'prop-types'
import designSystemIcons from '@yoco/design-system-icons/dist/fonts/YocoDesignSystemIcons.css'

import businessRoutes from 'containers/business/routes'
import homeRoutes from 'containers/home/routes'
import salesRoutes from 'containers/sales/routes'
import capitalRoutes from 'containers/capital/routes'
import onboardingRoutes from 'containers/onboarding/routes'
import marketplaceRoutes from 'containers/marketplace/routes'
import growthRoutes from 'containers/growth/routes'
import storeRoutes from 'containers/store/routes'
import invoiceRoutes from 'containers/invoices/routes'
import profileRoutes from 'containers/profile/routes'
import partnerRoutes from 'containers/partners/routes'
import buyHardwareRoutes from 'containers/hardware/routes'
import voucherRoutes from 'containers/vouchers/routes'
import onlineRoutes from 'containers/online/routes'
import devRoutes from 'containers/dev/routes'
import hubRoutes from 'containers/hub/routes'
import { inAppLoginUsed } from 'redux/modules/session'
import { removeMessage } from 'redux/modules/notifications'
import SecondaryNavbar from 'components/navbars/SecondaryNavbar'
import Alert from 'components/notifications/Alert'
import { callJSONApi } from 'libs/api'
import UserAlert from 'components/notifications/UserAlert'
import { FirebaseHandler, features } from 'libs/firebase'
import { userHasRequiredRoles } from 'libs/roles'
import localisation, { doesAcceptCardPayments } from 'libs/localisation/localisation'
import { hasFeature, hasRequiredFeatures } from 'libs/features'
import { authHandler } from 'libs/firebase/authHandler'
import LoadingView from 'components/loaders/LoadingView'
import { wasRoutedFromApp } from 'redux/modules/routes'
import { isFlagship, getENV } from 'libs/utils'

import classes from './portal.module.scss'

console.log('Imported design system icons', designSystemIcons)

function renderRoute(route) {
  if (route.path) {
    return (
      <Route path={route.path} key={route.path} component={route.component}>
        {route.indexRedirect}
        {(route.routes || []).map((childRoute) => renderRoute(childRoute))}
        {(route.redirects || []).map((redirect) => (
          <Redirect key={redirect.from} from={redirect.from} to={redirect.to} />
        ))}
      </Route>
    )
  }

  if (route.routes) {
    console.error('YOU CANNOT HAVE MORE ROOTS UNDER THE INDEX ROUTE')
  }
  return <Route component={route.component} key='index' />
}

export function getRoutesArray(isReseller = false, hasNewStaffManagementEnabled = false) {
  const routes = [
    onboardingRoutes(),
    homeRoutes(),
    salesRoutes(),
    storeRoutes(hasNewStaffManagementEnabled),
    invoiceRoutes(),
    onlineRoutes(),
    businessRoutes(),
    capitalRoutes(),
    profileRoutes(),
    partnerRoutes(),
    buyHardwareRoutes(),
    voucherRoutes(),
    marketplaceRoutes(),
    hubRoutes(),
  ]

  if (!isReseller) {
    routes.splice(2, 0, growthRoutes())
  }

  if (getENV('NODE_ENV') === 'development') {
    routes.push(devRoutes())
  }

  return routes
}

export function getRedirectsArray() {
  return [
    {
      from: '/transactions',
      to: '/sales/history',
    },
    {
      from: '/transactions/invoices',
      to: '/sales/invoices',
    },
    {
      from: '/transactions/settlement',
      to: '/sales/settlements',
    },
    {
      from: '/complete-setup',
      to: '/',
    },
    {
      from: '/items',
      to: '/store/items',
    },
    {
      from: '/business/staff',
      to: '/store/staff',
    },
  ]
}

function userProps(props) {
  return props.receivedAllEvents ? props.user : props.session.get('user') || Map()
}
function featureProps(props) {
  const legacyFeatures = props.receivedAllEvents
    ? props.features
    : props.session.get('features') || Map()
  return legacyFeatures.merge(props.launchDarklyFeatures)
}

class UnconnectedPortalPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: this.props.loggedIn,
      isReseller: false,
    }
  }

  componentDidMount() {
    if (!this.props.loggedIn && this.props.isInApp) {
      this.props.dispatch(inAppLoginUsed())
      // When we are in POS we need to init firebase at this point and wait for all events
      // to have been received before setting loaded to true
      // before hiding the loading screen
      // otherwise we will get a permission error banner
      // showing while firebase is syncing its data
      FirebaseHandler.init()
    }
    if (this.props.loggedIn) {
      FirebaseHandler.init()
    }
    this.checkIfMerchantIsReseller()
  }

  checkIfMerchantIsReseller() {
    callJSONApi(
      '/business/isReseller',
      'GET',
      {},
      (response) => {
        if (response.status === 200 && response.data && response.data.isReseller) {
          this.setState({ isReseller: true })
        }
      },
      (error) => {
        console.log('Error checking if is reseller', error)
      },
      true,
      false
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.loaded) {
      let isLoaded = false

      // If we are not in POS and we are logged in, set loaded to true and init firebase
      if (!this.props.isInApp && nextProps.loggedIn) {
        FirebaseHandler.init()
        isLoaded = true
        // If we are in POS and we have received all firebase events, set loaded to true
      } else if (this.props.isInApp && nextProps.receivedAllEvents) {
        isLoaded = true
      }

      if (isLoaded) {
        this.setState({
          loaded: true,
        })
      }
    }
  }

  getLoader() {
    if (this.props.notifications.get('loadingCount') > 0) {
      this.tempLoadingCount = this.props.notifications.get('loadingCount')
      return <ProgressBar autoIncrement intervalTime={200} percent={15} />
    }

    if (this.tempLoadingCount > 0) {
      this.tempLoadingCount = 0
      return <ProgressBar intervalTime={200} percent={100} />
    }

    return undefined
  }

  getUserAlert() {
    if (this.state.userAlert && !isFlagship) {
      return (
        <UserAlert
          userAlert={this.state.userAlert}
          dismissUserAlert={() => this.setState({ userAlert: undefined })}
        />
      )
    }

    return undefined
  }

  getNotifications() {
    return (
      <div className={classes.notificationsContainer}>
        {this.props.notifications.get('messages').map((message) => {
          return (
            <Alert
              message={message.get('message')}
              messageType={message.get('messageType')}
              key={message.get('key')}
              onClose={() => {
                this.props.dispatch(removeMessage(message.get('key')))
              }}
            />
          )
        })}
        {this.getLoader()}
        {this.getUserAlert()}
      </div>
    )
  }

  testRouteAvailable() {
    let routes = getRoutesArray(this.state.isReseller)
    let { path } = this.props
    if (path.indexOf('/') === 0) {
      path = path.substr(1)
    }
    const paths = path.split('/')
    const requiresOneRole = []
    const requiresAllRoles = []
    const requiresOneOfFeatures = []
    const requiresAllOfFeatures = []
    const doesNotHaveOneOfFeatures = []
    const doesNotHaveAllOfFeatures = []
    let acceptsCardPayments = true
    let redirectOnAuthFail
    let redirectOnFeatureFail
    paths.forEach((innerPath) => {
      let route = routes.filter((filterRoute) => {
        return filterRoute.path === innerPath || (innerPath.length === 0 && !filterRoute.path)
      })
      if (route.length === 1) {
        route = route[0]

        if (route.redirectOnAuthFail) {
          redirectOnAuthFail = route.redirectOnAuthFail
        }

        if (route.redirectOnFeatureFail) {
          redirectOnFeatureFail = route.redirectOnFeatureFail
        }

        ;(route.requiresOneRole || []).forEach((oneRole) => requiresOneRole.push(oneRole))
        ;(route.requiresAllRoles || []).forEach((allRole) => requiresAllRoles.push(allRole))
        ;(route.requiresOneOfFeatures || []).forEach((oneFeature) =>
          requiresOneOfFeatures.push(oneFeature)
        )
        ;(route.requiresAllOfFeatures || []).forEach((allFeature) =>
          requiresAllOfFeatures.push(allFeature)
        )
        ;(route.doesNotHaveOneOfFeatures || []).forEach((notOneFeature) =>
          doesNotHaveOneOfFeatures.push(notOneFeature)
        )
        ;(route.doesNotHaveAllOfFeatures || []).forEach((notAllFeature) =>
          doesNotHaveAllOfFeatures.push(notAllFeature)
        )
        acceptsCardPayments = doesAcceptCardPayments(this.props.localisation, route)

        routes = route.routes || []
      }
    })

    const userHasRoles = userHasRequiredRoles(userProps(this.props), {
      requiresAllRoles,
      requiresOneRole,
    })
    const hasFeatures = hasRequiredFeatures(featureProps(this.props), {
      requiresOneOfFeatures,
      requiresAllOfFeatures,
      doesNotHaveOneOfFeatures,
      doesNotHaveAllOfFeatures,
    })

    let isRedirecting = false

    if (!userHasRoles && redirectOnAuthFail) {
      isRedirecting = true
      setTimeout(() => this.props.dispatch(push(redirectOnAuthFail)), 0)
    }

    if (!hasFeatures && redirectOnFeatureFail) {
      isRedirecting = true
      setTimeout(() => this.props.dispatch(push(redirectOnFeatureFail)), 0)
    }

    return {
      routeAvailable: userHasRoles && hasFeatures && acceptsCardPayments,
      isRedirecting,
    }
  }

  renderChildren() {
    const routeAvailability = this.testRouteAvailable()
    if (routeAvailability.routeAvailable) {
      return this.props.children
    }

    if (!routeAvailability.isRedirecting) {
      const message = `You do not have permission to view this page. Ask your business owner to grant you permission.`
      return <Alert message={message} messageType='warning' key='permission-warning' />
    }

    return <LoadingView message='Preparing your portal...' />
  }

  renderPortalContent() {
    let className = classes.portalContent
    if (this.props.isInApp) {
      className = `${className} ${classes.inApp}`
    }
    return (
      <div className={className}>
        {this.getNotifications()}
        {this.renderChildren()}
      </div>
    )
  }

  renderMenus(routes, rootPath, secondaryPath, fullName, businessName) {
    if (!this.props.isInApp) {
      return [
        <SecondaryNavbar
          key='secondary'
          businessName={businessName}
          fullName={fullName}
          showAccountSwitcher={this.props.showAccountSwitcher}
        />,
      ]
    }

    return undefined
  }

  render() {
    let path = this.props.path || ''
    if (s(path).startsWith('/')) {
      path = path.substring(1)
    }

    const paths = path.split('/')
    const rootPath = paths.length > 0 ? paths[0] : undefined
    const secondaryPath = paths.length > 1 ? paths[1] : undefined
    const routes = Immutable.fromJS(getRoutesArray(this.state.isReseller))
      .filter((route) => route.get('path') === rootPath || (!route.get('path') && !rootPath))
      .toJS()

    return (
      <div id='portalContainer' className={`${classes.portalContainer} touch`}>
        {this.renderMenus(
          routes,
          rootPath,
          secondaryPath,
          userProps(this.props).get('fullName'),
          (this.props.session || Map()).getIn(['business', 'displayName'])
        )}
        {this.renderPortalContent(routes, rootPath, secondaryPath)}
      </div>
    )
  }
}

UnconnectedPortalPage.propTypes = {
  loggedIn: PropTypes.bool,
  path: PropTypes.string,
  dispatch: PropTypes.func,
  localisation: PropTypes.objectOf(Map),
  children: PropTypes.node,
  isInApp: PropTypes.bool,
  receivedAllEvents: PropTypes.bool,
  showAccountSwitcher: PropTypes.bool,
  session: PropTypes.objectOf(Map),
  // eslint-disable-next-line react/no-unused-prop-types
  launchDarklyFeatures: PropTypes.objectOf(Map),
  notifications: PropTypes.objectOf(Map),
}

const FirebaseConnectedPage = FirebaseHandler.connect(
  UnconnectedPortalPage,
  Map({
    user: {
      path: 'readOnly/store/users/{userUUID}',
      onNoValueOrError: () => {
        authHandler.noFirebaseUserFound()
      },
    },
    features,
  })
)

export const PortalPage = connect((state) => ({
  path: state.routing.locationBeforeTransitions.pathname,
  business: state.session.get('business'),
  queryParams: state.routing.locationBeforeTransitions.query,
  loggedIn: state.session.get('loggedIn'),
  notifications: state.notifications,
  session: state.session,
  launchDarklyFeatures: state.launchDarkly,
  localisation: localisation(state),
  firebaseAuthFailed: state.session.get('firebaseAuthFailed', false),
  isInApp: wasRoutedFromApp(state),
  showAccountSwitcher: hasFeature(state, 'linkedAccounts'),
  hasNewStaffManagementEnabled: hasFeature(state, 'new-staff-management-experience'),
}))(FirebaseConnectedPage)

export default function getPortalRoutes() {
  return (
    <Route component={PortalPage}>
      {getRedirectsArray().map((redirect) => {
        return <Redirect key={redirect.from} from={redirect.from} to={redirect.to} />
      })}
      {getRoutesArray().map((route) => {
        return renderRoute(route)
      })}
    </Route>
  )
}
