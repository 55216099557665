import React from 'react'
import PropTypes from 'prop-types'
import { Route, Router, browserHistory, createRoutes, match } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import numeral from 'numeral'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import getPortalRoutes from 'containers/portal/PortalPage'
import getAccountRoutes from 'containers/account/routes'
import UnsubscribePage from 'containers/account/UnsubscribePage'
import TermsAcceptedPage from 'containers/account/TermsAcceptedPage'
import AccountPage from 'containers/account/AccountPage'
import ReceiptPage from 'containers/receipts/ReceiptPage'
import PageNotFoundPage from 'containers/errors/PageNotFoundPage'
import App from 'containers/app/App'
import { initializeUnauthorizedHandler } from 'libs/api'
import { initializeFirebaseAuthHandler } from 'libs/firebase'
import { getENV } from 'libs/utils'
import MigratePage from 'containers/hub/MigratePage'
import { connect } from 'react-redux'
import { wasRoutedFromApp } from 'redux/modules/routes'

const routes = ({ store, isInApp }) => {
  numeral.language('en', {
    delimiters: {
      thousands: ' ',
      decimal: '.',
    },
    abbreviations: {
      thousand: 'K',
      million: 'M',
      billion: 'B',
      trillion: 'T',
    },
    ordinal(number) {
      return number === 1 ? 'er' : 'ème'
    },
    currency: {
      symbol: 'R',
    },
  })

  initializeUnauthorizedHandler(store)
  initializeFirebaseAuthHandler(store)

  const history = syncHistoryWithStore(browserHistory, store)

  return (
    <Router history={history}>
      <Route path='/' component={App}>
        {getAccountRoutes()}
        {isInApp ? (
          <>
            {getPortalRoutes()}
            <Route path='business/reports/unsubscribe/' component={AccountPage}>
              <Route path=':uuid' component={UnsubscribePage} />
            </Route>
            <Route path='terms-accepted' component={AccountPage}>
              <Route component={TermsAcceptedPage} />
            </Route>
            <Route path='receipts/:uuid' component={ReceiptPage} />
            <Route path='*' component={PageNotFoundPage} />
          </>
        ) : (
          <Route path='*' component={MigratePage} />
        )}
      </Route>
    </Router>
  )
}

const origin = new URL(getENV('CORE_URL')).hostname

Sentry.init({
  integrations: [
    new BrowserTracing({
      tracingOrigins: ['localhost', origin, /^\//],
      routingInstrumentation: Sentry.reactRouterV3Instrumentation(
        browserHistory,
        createRoutes(routes),
        match
      ),
    }),
  ],
  tracesSampleRate: 0.2,
})

routes.propTypes = {
  store: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  isInApp: wasRoutedFromApp(state),
})

export default connect(mapStateToProps)(routes)
